
.v-tabs--inline {
    --tabs-height: 38px;
    &.v-tabs {
        ::v-deep{
            .v-tabs-bar{
                background-color: transparent !important;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-tabs--inline {
        --tabs-height: 48px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

