
::v-deep {
    .v-bottom-sheet {
        background-color: rgba(0, 0, 0, 0.8) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .v-btn--absolute.v-btn--top,
        .v-btn--fixed.v-btn--top {
            top: 46px;
        }
        .v-btn--absolute.v-btn--right,
        .v-btn--fixed.v-btn--right {
            right: 46px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
