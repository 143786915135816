
/* sub-visual */
.sub-visual {
    position:relative;
    display: flex;
    align-items: flex-end;
    height: 200px;
    padding-bottom: var(--contents-padding-top);
    &--sub-tab{
        height: 272px;
    }
    .container{
        margin: 0 auto;
    }
}
.sub-visual__bg {
    position: absolute;
    top:0;
    right: 0;
    z-index: -1;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(/images/sub/sub-visual.jpg);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 320px;
        &--sub-tab{
            height: 428px;
        }
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 508px;
        &--sub-tab{
            height: 616px;
        }
    }
}
@media (min-width: 1200px) {
    .sub-visual__bg {
        min-width: 1920px;
        max-width: 1920px;
    }
}
