
.icon-download {
    width: 18px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .icon-download {
        width: 24px;
    }
}
@media (min-width:1200px){
}

