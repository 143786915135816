
.academy-video{
    --card-border-radius: 6px;
    background-image: url(/images/ci-white.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 80px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .academy-video{
        --card-border-radius: 12px;
        background-size: auto 120px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .academy-video{
        &__video{
            min-width: 588px;
        }
    }
}

