
::v-deep {
    .v-expansion-panel-content__wrap {
        padding: 0;
    }
    .v-expansion-panel--active + .v-expansion-panel,
    .v-expansion-panel--active:not(:first-child) {
        margin-top: 0;
    }
    .v-expansion-panel-header__icon {
        .v-icon {
            color: var(--v-grey-base) !important;
        }
    }
}
